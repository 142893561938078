import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ItemModel} from 'projects/library/src/model/item-model';
import {ILanguage} from 'projects/library/src/services/language/i-language';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  @Input()
  languages: ILanguage[];

  @Input()
  default: ILanguage;


  @Output()
  change: EventEmitter<ILanguage> = new EventEmitter<ILanguage>();

  public current: ILanguage;

  constructor() { }

  ngOnInit(): void {
    this.current = this.default;
  }

  onSelect(lang: ILanguage): void {
    this.current = lang;
    this.change.emit(lang);
  }
}
