/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {NavigationSegments} from 'projects/admin/src/app/navigation/navigation-segments.enum';
import {LocationModel} from 'projects/library/src/model/location-model';
import {LocationService} from 'projects/library/src/services/location/location.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public searching: boolean;

  private _logger: LoggerInterface;
  private _subs: Subscription[];


  constructor(
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    private _locationService: LocationService,
  ) {
    this._logger = LoggerService.getLogger('Login Component');
    this._subs = [];
    this.searching = false;
  }

  ngOnInit(): void {
    this._logger.ENABLED && this._logger.info('OnInit' );
  }


  /**
   * Component is about to be destroyed
   */
  ngOnDestroy(): void {
    this._logger.ENABLED && this._logger.log('OnDestroy');
    this._subs.forEach(s => s.unsubscribe() );
  }




  submit(email: string , password: string ): void {

    // Validate
    if (email.length === 0 || password.length === 0){
      this.onError('email or password empty' );
      return;
    }


    // OK? buscamos
    this.searching = true;
    this.locationFind(email , password );
  }


  private locationFind(email: string , password: string): void {
    this._logger.ENABLED && this._logger.info('locationFind' , email , password);

    this._subs.push(this._locationService.error$.subscribe(this.onError.bind(this) ));
    this._subs.push(this._locationService.complete$.subscribe(this.locationOnComplete.bind(this) ));
    this._locationService.getLocationByLogin(email , password);
  }

  private onError(e: string ): void {
    alert(e);
    this.searching = false;
  }


  private locationOnComplete(oLocation: LocationModel ): void {
    this._navigationService.navigateTo(NavigationSegments.LOCATION);
  }




}
