<!--: {{data.subject | titlecase}}-->
<h2 mat-dialog-title>Prosodik</h2>

<div mat-dialog-content class="text-base" >
  <p>
    Prosodik is part of XXXXXX. A quite experienced company providing full museographic and multimedia worldwide service.<br>
    Our services includes (but not limited to):
  </p>

  <ul>
    <li>
      Fit-out, design and implementation.<br>
    </li>
    <li>
      Electrical and BIM.
    </li>
    <li>
      Lightning design and installation.
    </li>
    <li>
      Our own studio for audio design, recording and production.
    </li>
    <li>
      Interactive elements: touchscreens, media installations, sync content, etc.
    </li>
    <li>
      AV, Video recording and postproduction,
    </li>
    <li>
      Interactivity and software: touchscreens, media installations, CMS, sync content.
    </li>
  </ul>



  <p *ngIf="data.subject === HelpSubject.GENERAL" ></p>


  <p *ngIf="data.subject === HelpSubject.MAP">
    Our Technical Team includes architects, engineers and topographers as well.<br>
    We can help you by measuring the Museum and creating high quiality maps.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.SYNC">
    Our AV Team will create the videos with stunning quality and will embed the audio tracks, ensuring that everything fits perfect.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.TRANSLATION">
    Our Contents Team includes creatives, writers, editors, translators, and text reviewers.<br>
    We can create all the texts for you in every desired language, ensuring that everything works as expected.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.STORYBOARD">
    Our Contents Team includes creatives, writers, editors, translators, and text reviewers.<br>
    We can create all the texts for you in every desired language, ensuring that everything works as expected.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.IMAGE">
    Our Media Team includes art direction, photographers and designers.<br>
    We can make the potographic session at our own studio or travel on-site.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.VIDEO">
    Our Media Team includes art direction, video editors, VFX, colorists, postproduction...<br>
    We can make the video session at our own studio or travel on-site.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.GIGAPIXEL">
    Our Media Team includes art direction, photographers and editors.<br>
    We use the last motorized and robotic camera joints with nodal correction, so the Gigapixel is taken with no distortion at all.<br>
    Then. we apply photocorrection to remove faces, car plates, tripod, feets, or any other private content.<br>
    We can make the potographic session at our own studio or travel on-site.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.CYLINDER">
    Our Media Team includes art direction, photographers and editors.<br>
    We use the last motorized and robotic rotary bases to ensure maximum quality and safety for the piece.<br>
    All the sequence is properly edited to remove shinings and reflections.<br>
    We can make the potographic session at our own studio or travel on-site by providing our own equipment.<br>
    Aliquam egestas, odio sit amet efficitur pulvinar, sapien neque convallis odio, eu placerat lorem sapien eu mi. Aenean ac leo nisl. Integer massa nisi, varius ac justo et, sodales ultrices eros. Phasellus nec neque a odio efficitur sollicitudin. Ut placerat, lacus ac ultricies consequat, nunc leo condimentum enim, non elementum quam augue quis metus. Morbi ullamcorper odio enim, quis posuere enim luctus non.
  </p>

  <p *ngIf="data.subject === HelpSubject.MODEL">
    Our Media Team includes 3D artists, shaders, and designers.<br>
    We use the last software tools to create a 3D model for you.<br>
    We can also travel onsite and 3d scan your piece in place.<br>
  </p>


  <p *ngIf="data.subject === HelpSubject.AR">
    Our Media Team includes 3D artists, shaders, designers and programmers.<br>
    We use the last software tools to create a 3D model and create a complete AR experience.<br>
    We can also travel onsite and 3d scan your piece in place.<br>
  </p>



  <p *ngIf="data.subject === HelpSubject.AUDIO">
    Our Audio Team includes locutors and actors in every language.<br>
    We offer high quality Synthetic Neuronal "human-near" voices at a fraction of the price.<br>
  </p>

  <h3  style="margin-top:20px;">Your enquiry here</h3>
  <textarea style="width: 100%; height:250px; padding:20px;"></textarea>

</div>


<div mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="false" cdkFocusInitial >cancel</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial >submit</button>
</div>
