<div class='component-container'>

  <!-- tabs -->
  <mat-tab-group animationDuration="0ms" [tabIndex]="tabSelectedIndex" (selectedTabChange)="infoTabOnChanged($event)" class="mat-elevation-z7 editor-tab-group" >
    <mat-tab *ngFor="let tab of tabs" label="{{tab}}" ></mat-tab>
  </mat-tab-group>


  <!--contenidos-->
  <div class="editor-dual" >

    <!--mapa-->
    <ng-container *ngIf="tabSelectedIndex === 0">
      <div class="list" >
        <app-list-editor #imageList [collection]="locationService.location.images" field="name" (add$)="imageAdd()" (delete$)="imageDelete($event)" ></app-list-editor>
      </div>

      <div class="editor">
        <app-location-image-editor [image]="imageList.selected" (help)="onHelp($event)" (save)="onSave($event)" ></app-location-image-editor>
      </div>
    </ng-container>


    <!--usuarios-->
    <ng-container *ngIf="tabSelectedIndex === 1">
      <div class="list" >
        <app-list-editor #userList [collection]="locationService.location.users" field="name" (add$)="userAdd()" (delete$)="userDelete($event)" ></app-list-editor>
      </div>

      <div class="editor">
        <app-location-user-editor [user]="userList.selected"  (help)="onHelp($event)" (save)="onSave($event)"></app-location-user-editor>
      </div>
    </ng-container>


    <!--items-->
    <ng-container *ngIf="tabSelectedIndex === 2 && items != null ">
      <div class="list" >
        <app-list-editor #itemList [collection]="items" field="tag" (add$)="itemAdd()" (delete$)="itemDelete($event)" ></app-list-editor>
      </div>

      <div class="editor">
        <app-location-item-editor [item]="itemList.selected" (help)="onHelp($event)" (save)="onSave($event)" ></app-location-item-editor>
      </div>
    </ng-container>




  </div>


</div>
