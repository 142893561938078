import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocationImage} from 'projects/library/src/model/location-model';
import {HelpSubject} from 'projects/admin/src/app/model/help';

@Component({
  selector: 'app-location-image-editor',
  templateUrl: './location-image-editor.component.html',
  styleUrls: ['./location-image-editor.component.scss']
})
export class LocationImageEditorComponent implements OnInit {

  @Input()
  image: LocationImage;


  public HelpSubject = HelpSubject;

  @Output()
  save: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  help: EventEmitter<HelpSubject> = new EventEmitter<HelpSubject>();

  onSave(): void {
    this.save.emit('image saved');
  }

  onHelp(subject?: HelpSubject): void {
    this.help.emit(subject);
  }



  constructor() { }

  ngOnInit(): void {
  }

}
