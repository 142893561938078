import {EventEmitter, Injectable} from '@angular/core';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {LocationModel} from 'projects/library/src/model/location-model';
import {HttpLoaderService} from 'projects/library/src/services/http-loader/http-loader.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {DynamoResponseSingle} from 'projects/library/src/model/aws-interfaces';
import {ConfigService} from 'projects/library/src/services/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {


  public static __config: any;
  private _logger: LoggerInterface;

  public logged: boolean;
  public location: LocationModel;
  public complete$: EventEmitter<LocationModel>;
  public error$: EventEmitter<string>;



  public static setConfig(d: any): void {
    LocationService.__config = d;
  }

  public static getConfig(): any {
    return LocationService.__config ;
  }



  constructor(
    private _httpLoaderService: HttpLoaderService,
    private configService: ConfigService
  ) {
    LocationService.setConfig(configService.getValue('location'));
    this._logger = LoggerService.getLogger('Spectacle Service');
    this.error$ = new EventEmitter<string>();
    this.complete$ = new EventEmitter<LocationModel>();
    this.logged = false;
  }


  private get _config(): any {
    return LocationService.getConfig() ;
  }


  public getLocationById(locationID: string): void {
    const url: string = this._config.find_by_id_url;
    const mode: string = this._config.find_by_id_mode;
    this._httpLoaderService.httpLoad( url , mode , { locationID: locationID} ).subscribe(this.locationOnResultSingle.bind(this) );
  }

  public getLocationByLogin(email: string , password: string): void {
    const url: string = this._config.find_by_login_url;
    const mode: string = this._config.find_by_login_mode;
    this._httpLoaderService.httpLoad( url , mode , { email: email , password: password } ).subscribe(this.locationOnResultSingle.bind(this) );
  }



  private locationOnResultSingle(dbResult: DynamoResponseSingle<LocationModel> ): void {
    if (
      !dbResult ||
      !dbResult.Item
    ) {
      this._logger.ENABLED && this._logger.log('No Location found with given id');
      this.error$.emit('No Location found with given id');
      return;
    }

    this.locationOnSucess(dbResult.Item);
  }


  private locationOnSucess(oLocation: LocationModel ): void {
    this.location = oLocation;
    this.complete$.emit(this.location);
  }


  public locationSelect(oLocation: LocationModel): void {
    this.location = oLocation;
  }

}
