<mat-toolbar>
  <mat-label>{{user.name}}</mat-label>
  <span class="h-spacer"></span>

  <button mat-raised-button class="toolbar-button help"  (click)="onHelp(HelpSubject.GENERAL)" >
    <label>help</label>
  </button>

  <button mat-raised-button class="toolbar-button save" color="primary" (click)="onSave()" >
    <label>save</label>
  </button>

</mat-toolbar>


<div class="editor-form">

  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >id</label>
    </div>
    <div class="editor-field" >
      <input type="text" [(ngModel)]="user.id" disabled  >
    </div>
    <div class="editor-extra">
      <p>User ID (Not visible. internal use only)</p>
    </div>
  </section>


  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >name</label>
    </div>
    <div class="editor-field" >
      <input type="text" [(ngModel)]="user.name"  >
    </div>
    <div class="editor-extra">
      <p>User Name (required)</p>
    </div>
  </section>


  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >surname</label>
    </div>
    <div class="editor-field" >
      <input type="text" [(ngModel)]="user.surname"  >
    </div>
    <div class="editor-extra">
      <p>User Surname (required)</p>
    </div>
  </section>


  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >email</label>
    </div>
    <div class="editor-field" >
      <input type="text" [(ngModel)]="user.email"  >
    </div>
    <div class="editor-extra">
      <p>User Email (required)</p>
    </div>
  </section>


  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >password</label>
    </div>
    <div class="editor-field" >
      <input type="password" [(ngModel)]="user.password"  >
    </div>
    <div class="editor-extra">
      <p>User Password (required)</p>
    </div>
  </section>



</div>
