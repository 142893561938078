<mat-toolbar>
  <mat-label>{{image.name}}</mat-label>
  <span class="h-spacer"></span>


  <button mat-raised-button class="toolbar-button help"  (click)="onHelp(HelpSubject.GENERAL)" >
    <label>help</label>
  </button>

  <button mat-raised-button class="toolbar-button save" color="primary" (click)="onSave()" >
    <label>save</label>
  </button>

</mat-toolbar>


<div class="editor-form">

  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >image</label>
    </div>
    <div class="editor-field" >
      <div style="background-color: black; min-height: 400px">
        <img [src]="image.src" style="height: 100%; width: 100%; object-fit: contain">
      </div>
    </div>

    <div class="editor-extra">
      <p>
        Transparent vector image in SVG format.<br>
        Use #666666 hex color for lines as it improves visualization over the black background.<br>
        Ensure that there are no external references nor Javascript code in the SVG elements.<br>
        <a href="https://developer.mozilla.org/es/docs/Web/SVG/Element" target="_blank">svg reference</a>
      </p>

      <button mat-raised-button (click)="onHelp(HelpSubject.MAP)" >
        <label>help</label>
      </button>

    </div>

  </section>

  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >id</label>
    </div>
    <div class="editor-field" >
      <input type="text" [(ngModel)]="image.id" disabled  >
    </div>
    <div class="editor-extra">
      <p>
        Image internal ID (not editable)
      </p>
    </div>
  </section>


  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >name</label>
    </div>
    <div class="editor-field" >
      <input type="text"  [(ngModel)]="image.name" >
    </div>
    <div class="editor-extra">
      <p>
        Image internal Name (not public )
      </p>
    </div>
  </section>


  <section class="editor-tab-section">
    <div class="editor-label">
      <label mat-line >file</label><input style="display: none" id="input-file-id" type="file" />
    </div>

    <div class="editor-field" >
      <div class="flex-container-row">
        <input type="text"  [(ngModel)]="image.src" disabled >
        <button mat-raised-button button color="primary" >
          <label for="input-file-id">select</label>
        </button>
      </div>
    </div>

    <div class="editor-extra">
      <p>
        Image upload
      </p>
    </div>
  </section>



</div>
