import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocationImage, LocationUser} from 'projects/library/src/model/location-model';
import {HelpSubject} from 'projects/admin/src/app/model/help';

@Component({
  selector: 'app-location-user-editor',
  templateUrl: './location-user-editor.component.html',
  styleUrls: ['./location-user-editor.component.scss']
})
export class LocationUserEditorComponent implements OnInit {

  @Input()
  user: LocationUser;


  public HelpSubject = HelpSubject;

  @Output()
  save: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  help: EventEmitter<HelpSubject> = new EventEmitter<HelpSubject>();

  onSave(): void {
    this.save.emit('user saved');
  }

  onHelp(subject?: HelpSubject): void {
    this.help.emit(subject);
  }





  constructor() { }

  ngOnInit(): void {
  }

}
