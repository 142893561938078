<div class='component-container'>

  <div class="middle-center">

    <div class="loginContainer" >

      <div class="loginButton">

        <h1>Prosodik Admin Panel</h1>

        <div class="w-220">
          <span> Please, fill in your credentials to access the admin panel</span>
        </div>

      </div>


      <div class="loginInputs">

        <div>
          <mat-form-field class="w-220">
            <mat-label>User</mat-label>
            <input #email type='email' matInput placeholder="user@domain.com" >
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="w-220">
            <mat-label>Password</mat-label>
            <input #password type='password' matInput placeholder="Ex. yourpassword"  >
          </mat-form-field>
        </div>

        <div>
          <button mat-raised-button color="primary" class="w-220" [disabled]='this.searching' (click)='submit(email.value , password.value)'>submit</button>
        </div>

      </div>

    </div>

  </div>

</div>
