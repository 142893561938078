/**
 *  Factory funcion to be executed BEFORE the rest of elements
 * We'll use it to pre-load config, languages and translations, so everything get ready BEFORE the components
 * This way we'll not need to call this config in every component in a later stage
 */
import {JsonService} from 'projects/library/src/services/json/json.service';
import {JsonLoaded} from 'projects/library/src/services/json/json-loaded';
import {JsonCollectionName} from 'projects/library/src/services/json/json-collection-name.enum';
import {ConfigService} from 'projects/library/src/services/config/config.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {NosleepService} from 'projects/visitor/src/app/services/nosleep/nosleep.service';
import { Observable} from 'rxjs';


export function preloadFactory(jsonService: JsonService): any {

  /*
  Subscribe to the loaded$ Observable of the jsonService to receive every new loaded file
  We receive a JsonLoaded Object containing
    - The "concept" (lowercase name of the file to be loaded)
    - The path of the loaded file
    - The contents of the file
   */
  jsonService.loaded$.subscribe( (value: JsonLoaded) => {
    if (value.concept === JsonCollectionName.CONFIG){
      ConfigService.setConfig(value.data);
      LanguageService.setConfig(ConfigService.getValue('language'));
      LoggerService.setConfig(ConfigService.getValue('logger'));
      NavigationService.setConfig(ConfigService.getValue('navigation'));
    }
    else if (value.concept === JsonCollectionName.LANGUAGE){
      LanguageService.setLanguageCollection(value.data);
    }
    else if (value.concept === JsonCollectionName.TRANSLATION){
      LanguageService.setTranslationCollection(value.data);
    }
  });

  const preloadResults$: Observable<any> = jsonService.initialLoad([
    JsonCollectionName.CONFIG ,
    JsonCollectionName.LANGUAGE ,
    JsonCollectionName.TRANSLATION
  ]);

  // Returns a FUNCTION which returns a promise from the observable
  // return () => lastValueFrom( preloadResults$ );
  return () => preloadResults$.toPromise() ;
}


