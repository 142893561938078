import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AudioItemCollection,
  InfoItem,
  InfoText,
  ItemModel, MediaAr, MediaCompare, MediaCylinder, MediaGigapixel, MediaImage, MediaItemCollection, MediaModel, MediaSpherical, MediaVideo,
  RelationLinkCollection,
  RelationLinkItem,
  RelationTagCollection
} from 'projects/library/src/model/item-model';
import {ILanguage} from 'projects/library/src/services/language/i-language';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {ItemInfoType} from 'projects/library/src/model/item-info-type.enum';
import {HelpSubject} from 'projects/admin/src/app/model/help';
import {ItemMediaType} from 'projects/library/src/model/item-media-type.enum';

@Component({
  selector: 'app-location-item-editor',
  templateUrl: './location-item-editor.component.html',
  styleUrls: ['./location-item-editor.component.scss']
})
export class LocationItemEditorComponent implements OnInit {

  public clientURL: string = 'https://client.prosodik.com/#/item/';

  audio: AudioItemCollection;

  language: ILanguage;
  infoText: InfoText;
  infoData: InfoItem;
  infoLinks: RelationLinkCollection;
  infoTags: RelationTagCollection;

  mediaImages: MediaItemCollection<MediaImage>;
  mediaVideos: MediaItemCollection<MediaVideo>;
  mediaCompare: MediaItemCollection<MediaCompare>;
  mediaCylinders: MediaItemCollection<MediaCylinder>;
  mediaSphericals: MediaItemCollection<MediaSpherical>;
  mediaModels: MediaItemCollection<MediaModel>;
  mediaARs: MediaItemCollection<MediaAr>;
  mediaGigapixels: MediaItemCollection<MediaGigapixel>;


  private _item: ItemModel;
  @Input()
  set item(it: ItemModel){
    this._item = it;
    this.itemOnChanged();
  }
  get item(): ItemModel {
    return this._item;
  }





  public HelpSubject = HelpSubject;

  @Output()
  save: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  help: EventEmitter<HelpSubject> = new EventEmitter<HelpSubject>();

  onSave(): void {
    this.save.emit('item saved');
  }

  onHelp(subject?: HelpSubject): void {
    this.help.emit(subject);
  }

  constructor(
    public languageService: LanguageService,
  ) {
    //
  }


  ngOnInit(): void {
    //
  }


  itemLanguageSwap(lang: ILanguage ): void {
    this.language = lang;
  }

  itemOnChanged(): void {
    if (this._item){
      this.language = this.item.languages.find(l => l.code === this.languageService.languageSelected.code) || this.item.languages[0] ;
      this.infoText = this.item.information.find(i => i.type === ItemInfoType.TEXT) ;
      this.infoData = this.item.information.find(i => i.type === ItemInfoType.DATA) ;
      this.infoLinks = this.item.information.find(i => i.type === ItemInfoType.LINK) as RelationLinkCollection;
      this.infoTags = this.item.information.find(i => i.type === ItemInfoType.TAG)  as RelationTagCollection;

      this.mediaImages = this.item.media.find(i => i.type === ItemMediaType.IMAGE)  as MediaItemCollection<MediaImage>;
      this.mediaVideos = this.item.media.find(i => i.type === ItemMediaType.VIDEO)  as MediaItemCollection<MediaVideo>;
      this.mediaCompare = this.item.media.find(i => i.type === ItemMediaType.COMPARE)  as MediaItemCollection<MediaCompare>;
      this.mediaCylinders = this.item.media.find(i => i.type === ItemMediaType.CYLINDER)  as MediaItemCollection<MediaCylinder>;
      this.mediaSphericals = this.item.media.find(i => i.type === ItemMediaType.SPHERICAL)  as MediaItemCollection<MediaSpherical>;
      this.mediaModels = this.item.media.find(i => i.type === ItemMediaType.MODEL)  as MediaItemCollection<MediaModel>;
      this.mediaARs = this.item.media.find(i => i.type === ItemMediaType.AR)  as MediaItemCollection<MediaAr>;
      this.mediaGigapixels = this.item.media.find(i => i.type === ItemMediaType.GIGAPIXEL)  as MediaItemCollection<MediaGigapixel>;

      this.audio = this.item.audio;

    }

  }





}
