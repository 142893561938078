/* eslint-disable @typescript-eslint/no-inferrable-types */
import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';

import { Subscription } from 'rxjs';
import {LoggerInterface} from 'projects/library/src/services/logger/logger.interface';
import {ErrorService} from 'projects/library/src/services/error/error.service';
import {NavigationService} from 'projects/library/src/services/navigation/navigation.service';
import {JsonService} from 'projects/library/src/services/json/json.service';
import {LanguageService} from 'projects/library/src/services/language/language.service';
import {LoggerService} from 'projects/library/src/services/logger/logger.service';
import {NavigationSegments} from 'projects/visitor/src/app/navigation/navigation-segments.enum';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private _logger: LoggerInterface;
  private _subs: Subscription[];


  constructor(
    private _errorService: ErrorService,
    private _navigationService: NavigationService,
    private _jsonService: JsonService,
    public languageService: LanguageService,
    public dialog: MatDialog,
  ) {
    this._logger = LoggerService.getLogger('AppComponent');
    this._subs = [];
  }




  ngOnInit(): void {
    this._logger.ENABLED && this._logger.info('ngOnInit');

    if (!this._jsonService.status.initialized){
      this._errorService.add(0 , this._jsonService.status.message );
      this._navigationService.navigateTo(NavigationSegments.ERROR);
    }
  }

  ngAfterViewInit(): void {
    //
  }



  /**
   * Component is about to be destroyed
   */
  ngOnDestroy(): void {
    this._logger.ENABLED && this._logger.log('OnDestroy');
    this._subs.forEach(s => s.unsubscribe());
  }



  logout(): void {
    window.location.reload();
  }




}
