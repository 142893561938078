import { NgModule } from '@angular/core';
import {Routes, RouterModule, RouteReuseStrategy} from '@angular/router';
import {NavigationSegments} from 'projects/admin/src/app/navigation/navigation-segments.enum';
import {LoginComponent} from 'projects/admin/src/app/components/login/login.component';
import {LocationComponent} from 'projects/admin/src/app/components/location/location.component';






const routes: Routes = [
  {
    path: NavigationSegments.ROOT,
    redirectTo: NavigationSegments.LOGIN,
    pathMatch: 'full'
  },
  {
    path: NavigationSegments.LOGIN,
    component:  LoginComponent,
    data: {animation: 'Login'}
  },
  {
    path: NavigationSegments.LOCATION,
    component:  LocationComponent,
    data: {animation: 'Location'}
  },
  /*
  {
    path: NavigationSegments.ERROR,
    component:  ErrorComponent,
    data: {animation: 'Error'}
  },
  {
    path: NavigationSegments.ITEM + '/:id',
    component:  ItemComponent,
    data: {animation: 'Item'}
  }
  */

];



@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      useHash: true,
    }
  )],
  providers: [
/*
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    }
*/
  ],  exports: [RouterModule]
})
export class RoutingModule {


  constructor() {
    //
  }

}
