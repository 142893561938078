<!--icons -->
<mat-toolbar class="mat-elevation-z7 item-editor-toolbar" >
  <h4>{{item.tag}}</h4>

  <span class="h-spacer"></span>

<!--
  <app-language-selector [default]="language" [languages]="item.languages" (change)="language = $event"  ></app-language-selector>

  <button mat-raised-button aria-label="Language selection" class="toolbar-button help" [matMenuTriggerFor]="menu" color="primary">
    {{language.title}} <mat-icon>arrow_drop_down</mat-icon>
  </button>
-->
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let lang of item.languages" (click)="language = lang">{{lang.title}}</button>
  </mat-menu>


  <button mat-raised-button class="toolbar-button help"  (click)="onHelp(HelpSubject.GENERAL)" >
    <label>help</label>
  </button>

  <button mat-raised-button class="toolbar-button save" color="primary" (click)="onSave()" >
    <label>save</label>
  </button>


</mat-toolbar>




<!--<div style="background-color: black; min-height: 400px"></div>-->

<mat-accordion>

  <!--ID y TAG-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Identification
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >id</label>
        </div>
        <div class="editor-field">
          <input type="text" [(ngModel)]="item.itemID" disabled  >
        </div>
        <div class="editor-extra">
          <p>Item ID (not visible, internal use only) </p>
        </div>
      </section>

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >tag</label>
        </div>
        <div class="editor-field">
          <input type="text" [(ngModel)]="item.tag"   >
        </div>
        <div class="editor-extra">
          <p>Item Tag used to indentify this item in the list (not visible, internal use only) </p>
        </div>
      </section>

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >public url</label>
        </div>
        <div class="editor-field">
          <input type="text" class="editor-field" value="{{clientURL + item.itemID}}" disabled  >
        </div>
        <div class="editor-extra">
          <p>
            Public URL to access this item in the webApp. (generated by the system. not editable).<br>
            <a href="{{clientURL + item.itemID}}" target="_blank">test</a>
          </p>
        </div>
      </section>

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >qr code</label>
        </div>
        <div class="editor-field">
          <img #qr width="128" height="128"  [src]="clientURL + item.itemID | qr : 512 : 512 " >
        </div>
        <div class="editor-extra">
          <p>
            Public QR to access this item in the webApp. (generated by the system. not editable).<br>
            <a [href]="qr.src" target="_blank" download="qr_{{item.itemID}}.png">download</a>
          </p>

        </div>
      </section>

    </div>
  </mat-expansion-panel>


  <!-- FICHA -->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Data
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <ng-container *ngIf="languageService.translationFieldsAvailable(infoData , language.code ); let arrFields">

        <section class="editor-tab-section" *ngFor="let field of arrFields">
          <div class="editor-label">
            <label mat-line >{{field}}</label>
          </div>
          <div class="editor-field" style="direction: {{language.direction}}">
            <input type="text" [(ngModel)]="infoData.glossaryCollection[language.code][field]" >
          </div>
          <div class="editor-extra">
            <p>
              Fill in the <b>{{field}}</b> field in every <a href="javascript:void(0)" [matMenuTriggerFor]="menu">language</a>
            </p>
          </div>
        </section>

      </ng-container>

    </div>
  </mat-expansion-panel>


  <!--TEXTO-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Text
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >text</label>
        </div>
        <div class="editor-field" style="direction: {{language.direction}}">
          <textarea rows="20" >{{infoText.glossaryCollection[language.code]['text']}}</textarea>
        </div>
        <div class="editor-extra">
          <p>
            Type in the text to be read in every <a href="javascript:void(0)" [matMenuTriggerFor]="menu">language</a>.<br>
            For spell and reading clarity, write every sentence in one independent paragraph.<br>
            Don't copy/paste from Microsoft Word as it will add weird invisible characters.<br>
            Don't use double nor single quotes as they interfere with the spell system. Use «literary quotes» instead.<br>
            Use a plain text editor instead like "Sublime text", "Atom" or "Notepad".
          </p>
          <p>
            In order the spell functionality to work properly, the text must match <b>EXACTLY</b> with the provided audio and the locution marks.<br>
            Any minor change on it will produce unexpected behaviors during the spell playback.
          </p>
          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.TRANSLATION)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>

  <!--AUDIO-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Audio
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >audio</label>
        </div>
        <div class="editor-field list">
          <div>
            <input type="text" [(ngModel)]="audio[language.code].url" placeholder="mp3 file here" >
            <input type="text" [(ngModel)]="audio[language.code].metadata" placeholder="locution marks file here" >
          </div>
        </div>
        <div class="editor-extra">
          <p>
            <b>Audio:</b><br>
            Upload an audio file for every <a href="javascript:void(0)" [matMenuTriggerFor]="menu">language</a>.<br>
            File MUST be enconded in MP3 format at 192mbps constant bitrate (CBR).<br>
          </p>
          <p>
            <b>Locution marks:</b><br>
            Upload a the locution marks file in MARKS format.<br>
            File MUST be enconded in JSON line format and must include a CHR13 delimiter characterafter each line.<br>
          </p>
          <p>
            In order the spell functionality to work properly, the text must match <b>EXACTLY</b> with the provided audio and the locution marks.<br>
            Any minor change on any of this files will produce unexpected behaviors during the spell playback.
          </p>
          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.MAP)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>

  <!--SYNC-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Video sync
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >sync</label>
        </div>
        <div class="editor-field">
          <mat-slide-toggle [(ngModel)]="item.sync">Sync with video</mat-slide-toggle>
        </div>
        <div class="editor-extra">
          <p>
            In order to sync with a video source, the following requirements must be met:<br>
          </p>

          <blockquote>
              <li>
                All the audios in ALL languages MUST have the same length.
              </li>
              <li>
                Use one of them as the background audio channel for the video.
              </li>
              <li>
                The video length MUST be identical to the rest of audio files.
              </li>
              <li>
                The video must be looped.
              </li>
              <li>
                The video must include a cue triggering an HTTP call to the Prosodik Sync server.
              </li>
          </blockquote>
          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.MAP)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>

  <!--ENLACES-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Links
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section" >
        <div class="editor-label">
          <label mat-line >links</label>
        </div>

        <div class="editor-field list" >
          <div *ngFor="let link of infoLinks.items">
            <input type="text" [(ngModel)]="link.glossaryCollection[language.code]['title']" >
          </div>
        </div>

        <div class="editor-extra">
          <p>
            Fill in the link data
          </p>
        </div>
      </section>

    </div>

  </mat-expansion-panel>

  <!--TAGS-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Tags
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section" >
        <div class="editor-label">
          <label mat-line >tag</label>
        </div>

        <div class="editor-field list" >
          <div *ngFor="let tag of infoTags.items">
            <input type="text" [(ngModel)]="tag.glossaryCollection[language.code]['title']" >
          </div>
        </div>

        <div class="editor-extra">
          <p>
            Fill in the tag data
          </p>
        </div>
      </section>

    </div>


  </mat-expansion-panel>

  <!--IMAGES-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Images
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section">
        <div class="editor-label">
          <label mat-line >img</label>
        </div>
        <div class="editor-field list" >
          <div *ngFor="let img of mediaImages?.items">
            <input type="text"  [(ngModel)]="img.url" >
          </div>
        </div>
        <div class="editor-extra">
          <p>
            Upload the image in either formats: JPG, GIF or PNG.<br>
            Transparent PNG's are allowed, BUT may produce odd results over the black background. Also, ensure proper encoding at 24 bytes.<br>
            Other formats (TGA, TIFF, BMP, etc) are NOT allowed.<br>
            Images <b>MUST</b> be in resolution 72 and not exceed 1000px on every side.<br>
          </p>
          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.IMAGE)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>


  <!--VIDEOS-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Videos
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section" >
        <div class="editor-label">
          <label mat-line >video</label>
        </div>
        <div class="editor-field list" >
          <div *ngFor="let video of mediaVideos?.items">
            <input type="text"  [(ngModel)]="video.url" >
          </div>
        </div>
        <div class="editor-extra">
          <p>
            Upload the video in MP4 format (WEBM videos are NOT allowed as the format is NOT universal).<br>
            Use H264 encapsulation.<br>
            Reduce the video dimensions to 960px max in either side.<br>
            Reduce the byterate to 2048 max in CBR mode.<br>
            Videos <b>MUST</b> not exceed 15Mb max.<br>
            Online videos (youtube, Vimeo) are not allowed.<br>
          </p>
          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.VIDEO)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>

  <!--GIGAPIXEL-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Gigapixel
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section" >
        <div class="editor-label">
          <label mat-line >gigapixel</label>
        </div>
        <div class="editor-field list" >
          <div *ngFor="let gp of mediaGigapixels?.items">
            <input type="file" multiple >
            <input type="text"  [(ngModel)]="gp.url" >
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="editor-extra">
          <p>
            Upload the Gigapixel piramidal image folder in either DZI, IIIF, TMS or Zoomify format.<br>
            Once uploaded, type in the definition file path in the proper format (XML, JSON, TXT).<br>
<!--
            Prosodik works with a variety of zooming image formats .<br>
            These zooming images generally consist of a number of individual tiles, organized so they can be accessed as needed. If you have a large image you'd like to zoom, you'll need to convert it first.
            Prosodik offers high quality Gigapixel works. With automated and robotic camera mounts. Also retouch, tripod, people and car plates hiding. Lot of conversion options are offered depending on your needs.
-->
          </p>
          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.GIGAPIXEL)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>


  <!--FOTOGRAFÍA CILINDRICAS-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Cylinder image
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section" >
        <div class="editor-label">
          <label mat-line >cylinder</label>
        </div>
        <div class="editor-field list" >
          <div *ngFor="let cy of mediaCylinders?.items">
            <input type="file" multiple >
            <input type="text"  [(ngModel)]="cy.url" >
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="editor-extra">
          <p>
            Upload the Cylinder in Spritesheet format.<br>
            The underlying image MUST be coded in either JPG or PNG format.<br>
            Transparent PNG's are allowed, BUT may produce odd results over the black background. Also, ensure proper encoding at 24 bytes.<br>
            Other formats (TGA, TIFF, BMP, etc) are NOT allowed.<br>
            Images <b>MUST</b> be in resolution 72 and not exceed 1000px on every side.<br>
          </p>
          <p>
            The spritesheet file MUST be encoded in JSON format.
          </p>
          <p>
            Prosodik Cylinder images allow for hotspots definitions.<br>
            Those must be encoded in JSON format as well.
          </p>

          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.CYLINDER)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>

  <!--MODELO 3D-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        3D Model
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section" >
        <div class="editor-label">
          <label mat-line >3d model</label>
        </div>
        <div class="editor-field list" >
          <div *ngFor="let cy of mediaModels?.items">
            <input type="file" multiple >
            <input type="text"  [(ngModel)]="cy.url" >
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="editor-extra">
          <p>
            Upload the 3D Model in GLTF binary format (GLB).<br>
            Textures, occlussion maps, and alpha maps MUST be encoded and embedded within.<br>
            Animations are allowed as well.<br>
            Resulting model polygon count should be under 15k polygons.<br>
            Final file size should be under 10Mb.<br>
          </p>

          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.MODEL)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>

  <!--AR-->
  <mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Augmented Reality
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="editor-tab-content">

      <section class="editor-tab-section" >
        <div class="editor-label">
          <label mat-line >Augmented Reality</label>
        </div>
        <div class="editor-field list" >
          <div *ngFor="let cy of mediaARs?.items">
            <input type="text"  [(ngModel)]="cy.url" placeholder="3d overlay">
            <input type="text"  [(ngModel)]="cy.model" placeholder="3d model path" >
            <input type="text"  [(ngModel)]="cy.features" placeholder="3d features" >
            <mat-divider></mat-divider>
          </div>
        </div>
        <div class="editor-extra">
          <p>
            Upload the AR files:<br>
            <b>Model:</b> Use lightweight 3D models encoded in GLTF binary format.<br>
            <b>Overlay:</b> images encoded in JPG or even PNG formats.<br>
            <b>Features:</b> Upload a previously encoded binary feature file in MIND format.<br>
          </p>

          <button mat-raised-button class="toolbar-button"  (click)="onHelp(HelpSubject.AR)" >
            <label>help</label>
          </button>
        </div>
      </section>

    </div>

  </mat-expansion-panel>


</mat-accordion>


<div class="editor-form"></div>
