import { Component, OnInit } from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {LocationService} from 'projects/library/src/services/location/location.service';
import {LocationImage, LocationUser} from 'projects/library/src/model/location-model';
import {ItemModel} from 'projects/library/src/model/item-model';
import {HttpLoaderService} from 'projects/library/src/services/http-loader/http-loader.service';
import {Observable} from 'rxjs';
import {ItemService} from 'projects/library/src/services/item/item.service';
import {HelpSubject} from 'projects/admin/src/app/model/help';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HelpComponent, HelpDialogData} from 'projects/admin/src/app/components/help/help.component';

declare var uuidv4;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  public items: ItemModel[];
  public tabs: string[] = ['map' , 'users' , 'items'];
  public tabSelectedIndex: number = 0;

  constructor(
    public locationService: LocationService,
    private httpLoaderService: HttpLoaderService,
    private itemService: ItemService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.itemsLoad();
  }

  infoTabOnChanged(e: MatTabChangeEvent): void {
    console.log(e.tab.textLabel);
    this.tabSelectedIndex = e.index;
    // this.tabSelected = e.tab.textLabel;
  }




  imageAdd(): void {
    const img: LocationImage = {
      id: uuidv4(),
      name: 'new image',
      src: ''
    };
    this.locationService.location.images = [...this.locationService.location.images , img];
  }

  imageDelete(img: LocationImage): void {
    const i: number = this.locationService.location.images.indexOf(img);
    this.locationService.location.images = [...this.locationService.location.images.splice(i , 1)];
  }







  userAdd(): void {
    const user: LocationUser = {
      id: uuidv4(),
      name: 'new user',
      surname: '',
      email: '',
      password: ''
    };
    this.locationService.location.users = [...this.locationService.location.users , user];
  }

  userDelete(user: LocationUser): void {
    const i: number = this.locationService.location.users.indexOf(user);
    this.locationService.location.users = [...this.locationService.location.users.splice(i , 1)];
  }





  itemsLoad(): void {
    this.itemService.itemsByLocationID(this.locationService.location.locationID).subscribe( this.itemsOnReceived.bind(this) );
  }

  itemsOnReceived(arrItems: ItemModel[]): void {
    this.items = arrItems;
  }


  itemAdd(): void {
    const user: LocationUser = {
      id: uuidv4(),
      name: 'new user',
      surname: '',
      email: '',
      password: ''
    };
    this.locationService.location.users = [...this.locationService.location.users , user];
  }

  itemDelete(item: ItemModel): void {
    const i: number = this.locationService.location.items.indexOf(item);
    this.locationService.location.items = [...this.locationService.location.items.splice(i , 1)];
  }


  onHelp(helpSubject: HelpSubject): void {

    const oData: HelpDialogData = {
      subject: helpSubject
    };
    const oCOnfig: MatDialogConfig = {
      minWidth: '50%' ,
      data: oData
    };
    const dialogRef: MatDialogRef<HelpComponent> = this.dialog.open(HelpComponent , oCOnfig );
    // dialogRef.afterClosed().subscribe()
  }

  onSave(msg: string): void {
    alert(msg);
    this._snackBar.open(msg , null , {duration: 1000 });
  }

}
