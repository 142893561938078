<!--buscador-->
<mat-toolbar>

  <input #search type="text" placeholder="search" (input)="onInput(search.value)" >
  <mat-icon (click)="onClear()" color="primary">clear</mat-icon>

  <span class="h-spacer"></span>

  <mat-icon (click)="onAdd()" color="primary">add_circle</mat-icon>

</mat-toolbar>



<mat-selection-list [multiple]="false" (selectionChange)="onSelect($event.options[0].value)" >
  <mat-list-option *ngFor="let elem of filteredCollection" [value]="elem" class="list-row">
    <div style="display: flex; flex-direction: row; align-items: center">
      <span mat-line class="h-spacer" >{{elem[field]}}</span>
      <mat-icon (click)="onDelete(elem)" color="primary">delete</mat-icon>
    </div>

  </mat-list-option>
</mat-selection-list>
