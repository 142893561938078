export enum HelpSubject{
  GENERAL = 'general',
  MAP = 'map',
  SYNC = 'sync',
  TRANSLATION = 'translation',
  STORYBOARD = 'storyboard',
  IMAGE = 'image',
  VIDEO = 'video',
  GIGAPIXEL = 'gigapixel',
  CYLINDER = 'cylinder',
  MODEL = 'model',
  AR = 'ar',
  AUDIO = 'audio',

}



