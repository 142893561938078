import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['./list-editor.component.scss']
})
export class ListEditorComponent<T> implements OnInit {

  private _collection: T[];

  selected: T;

  @Input()
  set collection(c: T[]) {
    if (c) {
      this._collection = c;
      this.onClear();
    }
  }


  @Input()
  field: string;

  @Output()
  selected$: EventEmitter<T> = new EventEmitter<T>();

  @Output()
  add$: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  delete$: EventEmitter<T> = new EventEmitter<T>();


  public filteredCollection: T[];

  constructor() { }

  ngOnInit(): void {
    // this.onClear();
    // this.selected = this.filteredCollection[0];
  }



  onSelect(elem: T): void {
    this.selected = elem;
    this.selected$.emit(this.selected);
  }

  onInput(str: string): void {
    this.filteredCollection = this._collection.filter(elem => {
      const s: string = elem[this.field];
      return s.indexOf(str) >= 0 ;
    } );
  }

  onClear(): void {
    try {
      this.filteredCollection = [...this._collection];
      this.selected = this.filteredCollection[0];
    }catch (e){
      console.log(this.field , e);
    }
  }

  onAdd(): void {
    this.add$.emit();
  }

  onDelete(elem: T): void {
    this.delete$.emit(elem);
  }

}
