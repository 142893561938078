import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HelpSubject} from 'projects/admin/src/app/model/help';


export interface HelpDialogData{
  subject: HelpSubject;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  public HelpSubject = HelpSubject;

  constructor(
    public dialogRef: MatDialogRef<HelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HelpDialogData

  ) {
    //
  }


  ngOnInit(): void {

  }

}
